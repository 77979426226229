import logo from './logo.svg';
import './App.css';
import MainPage from './components/MainPage/MainPage';

function App() {
  return (
    <MainPage />
  );
}

export default App;
